import { Outlet } from 'react-router';

const Invoices = () => {
  return (
    <div className="guarantors invest">
      <Outlet />
    </div>
  );
};

export default Invoices;
