/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { Tab, TabList, Tabs } from 'react-tabs';
import Button from '../../../components/ui/Button';
import { StoreContext } from '../../../mobx_stores/RootStore';

const CreateInvoice = () => {
  const { loansStore } = useContext(StoreContext);
  const { loading } = loansStore;
  const [screen, setScreen] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(screen > 767 ? false : true);

  const [headerText, setHeaderText] = React.useState('');

  useEffect(() => {
    function handleResize() {
      setScreen(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    if (screen > 767) {
      setMobile(false);
    } else {
      setMobile(true);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [screen]);

  //Form Handlers
  const [selectedInvoice, setSelectedInvoice] = useState('');
  const navigate = useNavigate();

  const handleSelectChange = (e) => {
    setSelectedInvoice(e.target.value);
  };

  const handleEditDetails = () => {
    if (selectedInvoice) {
      navigate(`/dashboard/invoices/${selectedInvoice}`);
    }
  };

  return (
    <div>
      {loading ? (
        <div>
          <div className="bg-white guarantor-list">
            <div className="d-flex m-0 pt-4 guarantor-heade ">
              <h5>
                <Skeleton width={170} height={25} />
              </h5>
              <h5>
                <Skeleton width={170} height={25} />
              </h5>
              <h5>
                <Skeleton width={170} height={25} />
              </h5>
              <h5>
                <Skeleton width={170} height={25} />
              </h5>
            </div>

            <div className="navigation">
              <Skeleton width={40} height={40} />
              <Skeleton width={40} height={40} />
              <Skeleton width={40} height={40} />
              <Skeleton width={40} height={40} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h4 className="section-head">Create Invoice</h4>
          <div className="d-flex ">
            <h2 className="" style={{ color: '#054b99' }}>
              New Invoice
            </h2>
          </div>
          <div className="">
            <div className="guarantor-list">
              <Tabs>
                {mobile ? (
                  <>
                    <div className="guarantors-header-link">
                      <h5>{headerText === '' ? 'Invoice' : headerText}</h5>
                    </div>
                    <TabList
                      className={
                        'd-flex flex-column flex-md-row m-0 p-0 guarantor-heade automatic'
                      }>
                      <div className={'hidden-header'}>
                        <h3>Invoice Template</h3>
                        <Tab className="guarantors-header-link">
                          <select
                            name="invoice"
                            id="invoice"
                            className="form-control"
                            onChange={handleSelectChange}
                            required>
                            <option value="">Choose Invoice</option>
                            <option value="invoice1">Invoice 1</option>
                            <option value="invoice2">Invoice 2</option>
                            <option value="invoice3">Invoice 3</option>
                            <option value="invoice4">Invoice 4</option>
                            <option value="invoice5">Invoice 5</option>
                            <option value="invoice6">Invoice 6</option>
                            <option value="invoice7">Invoice 7</option>
                          </select>
                        </Tab>
                      </div>
                    </TabList>
                  </>
                ) : (
                  <div className="p-4" style={{ minHeight: '50vh' }}>
                    <div className="d-flex">
                      <h3 className="fw-semibold" style={{ color: '#a0a3bd' }}>
                        Invoice Template
                      </h3>
                    </div>
                    <hr />
                    <div className="col-md-6 row justify-content-center">
                      <label htmlFor="Invoice" className="d-flex mb-1 mt-3">
                        Invoice
                        <span style={{ color: 'red' }}> *</span>
                      </label>
                      <select
                        name="invoice"
                        id="invoice"
                        className="form-control"
                        required
                        onChange={handleSelectChange}>
                        <option value="">Choose Invoice</option>
                        <option value="invoice1">Invoice 1</option>
                        <option value="invoice2">Invoice 2</option>
                        <option value="invoice3">Invoice 3</option>
                        <option value="invoice4">Invoice 4</option>
                        <option value="invoice5">Invoice 5</option>
                        <option value="invoice6">Invoice 6</option>
                        <option value="invoice7">Invoice 7</option>
                      </select>
                    </div>
                    <div
                      className="profile-btnn d-flex"
                      style={{ marginTop: '10rem' }}>
                      <Button
                        classes="primary-btn"
                        content="Edit Details"
                        onClick={handleEditDetails}
                        disabled={!selectedInvoice}
                      />
                    </div>
                  </div>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateInvoice;
