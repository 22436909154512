/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { useStep } from 'react-hooks-helper';
import DocumentsDetails from './form/DocumentsDetails';
import DocumentsUpload from './form/DocumentsUpload';
import NextOfKin from './form/NextOfKin';
import PersonalDetails from './form/PersonalDetails';

type Props = {
  input: { [key: string]: any };
  setShowProfileModal: (state: any) => void;
};

interface Step {
  id: string;
}

const DetailsForm: FC<Props> = ({ setShowProfileModal }) => {
  const [x, setX] = useState(0);
  const { index, step, navigation } = useStep({
    steps: [
      { id: 'next of kin' },
      { id: 'personal details' },
      { id: 'document details' },
      { id: 'document upload' },
    ],
  });
  const { id } = step as Step;

  return (
    <div
      className="profile-multistep mt-0 pt-0"
      style={{
        scrollBehavior: 'smooth',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}>
      <div className="">
        <div className="row justify-content-center">
          <div className="col-sm-10">
            <div className="step-progress">
              <button
                type="button"
                disabled={true}
                onClick={() => {
                  if (index > 0) {
                    setX(-1000);
                  } else {
                    setX(1000);
                  }
                  navigation.go?.('next of kin');
                }}
                // disabled={true}
                className={`step ${
                  id === 'next of kin' ? 'active' : ''
                }`}></button>

              <button
                type="button"
                disabled={true}
                onClick={() => {
                  if (index > 1) {
                    setX(-1000);
                  } else {
                    setX(1000);
                  }
                  navigation.go?.('personal details');
                }}
                // disabled={true}
                className={`step ${
                  id === 'personal details' ? 'active' : ''
                }`}></button>
              <button
                type="button"
                disabled={true}
                onClick={() => {
                  if (index > 2) {
                    setX(-1000);
                  } else if (index < 2) {
                    setX(1000);
                  }
                  navigation.go?.('document details');
                }}
                // disabled={true}
                className={`step ${
                  id === 'document details' ? 'active' : ''
                }`}></button>

              <button
                type="button"
                disabled={true}
                onClick={() => {
                  if (index < 3) {
                    setX(1000);
                  } else {
                    setX(-1000);
                  }
                  navigation.go?.('document upload');
                }}
                className={`step ${
                  id === 'document upload' ? 'active' : ''
                }`}></button>
            </div>

            {id === 'next of kin' && (
              <NextOfKin x={x} setX={setX} next={navigation.next} />
            )}
            {id === 'personal details' && (
              <PersonalDetails
                x={x}
                setX={setX}
                next={navigation.next}
                previous={navigation.previous}
              />
            )}
            {id === 'document details' && (
              <DocumentsDetails
                x={x}
                setX={setX}
                next={navigation.next}
                previous={navigation.previous}
              />
            )}

            {id === 'document upload' && (
              <DocumentsUpload
                x={x}
                setX={setX}
                next={navigation.next}
                previous={navigation.previous}
                setShowProfileModal={setShowProfileModal}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(DetailsForm);
