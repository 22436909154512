/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, FormEvent, useState } from 'react';
// import BankDetails from './profileFormSteps/BankDetails'
// import BusinessDetails from './profileFormSteps/BusinessDetails'
import { mdiArrowLeft, mdiLogout } from '@mdi/js';
import Icon from '@mdi/react';
import { useStep } from 'react-hooks-helper';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../../../components/ui/Spinner';
import { persistor } from '../../../redux';
import { logout } from '../../../redux/auth/auth.action';
import { clearReduxStateAction } from '../../../redux/root-reducer';
import { LogOut } from '../../../utils/firebase/AuthFirestore';
import AccountType from './profileFormSteps/AccountType';
import BusinessDetails from './profileFormSteps/BusinessDetails';
import BvnValidate from './profileFormSteps/BvnValidate';
import CreatePin from './profileFormSteps/CreatePin';
import NextOfKinDetails from './profileFormSteps/NextOfKinDetails';
import PersonalDetails from './profileFormSteps/PersonalDetails';

interface Step {
  id: string;
}

const ProfileMultistepForm: FC = () => {
  const [x, setX] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { index, step, navigation } = useStep({
    steps: [
      { id: 'account type' },
      { id: 'BVN validation' },
      { id: 'personal details' },
      { id: 'business details' },
      { id: 'next of kin' },
      { id: 'create pin' },
    ],
  });
  const { id } = step as Step;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleLogOut = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    await LogOut();
    dispatch(clearReduxStateAction());
    window.location.href = '/login';
    setTimeout(async () => {
      setLoading(false);
      dispatch(logout());
      persistor.pause();
      await persistor.flush().then(async () => {
        return await persistor.purge();
      });
      window.location.reload();
    }, 500);
  };
  return (
    <div
      className="profile-multistep"
      style={{ height: '100%', minHeight: '100vh' }}>
      {loading && <Spinner />}
      <div className="container">
        <div className="row justify-content-center">
          <div className="d-md-none d-flex text-start mt-4 back-top">
            <Link to="/">
              <Icon path={mdiArrowLeft} size={1} style={{ color: '#054b99' }} />
            </Link>
          </div>
          <div className="col-sm-10">
            {/* <div className="d-flex flex-wrap-reverse justify-content-left">
              <div className="sidebar-logout-btn align-self-center">
                <button
                  type="button"
                  onClick={handleLogOut}
                  className="text-nowrap me-4"
                >
                  <Icon
                    path={mdiLogout}
                    title="User Profile"
                    size={1}
                    horizontal
                  />
                  <span>Sign out</span>
                </button>
              </div>
              <div className="align-self-center" style={{ marginLeft: '33%' }}>
                <img
                  src={`${BaseDirectories.LOGOS_DIR}/logo1.png`}
                  alt=""
                  style={{ width: 'auto', height: '44px' }}
                />
              </div>
            </div> */}

            <div className="step-progress">
              <button //account btn
                type="button"
                // disabled={true}
                onClick={() => {
                  if (index > 0) {
                    setX(-1000);
                  } else {
                    setX(1000);
                  }
                  navigation.go?.('account type');
                }}
                className={`step ${
                  id === 'account type' ? 'active' : ''
                }`}></button>
              <button //bvn validation btn
                type="button"
                // disabled={true}
                onClick={() => {
                  if (index > 1) {
                    setX(-1000);
                  } else {
                    setX(1000);
                  }
                  navigation.go?.('BVN validation');
                }}
                className={`step ${
                  id === 'BVN validation' ? 'active' : ''
                }`}></button>
              <button //personal details btn
                type="button"
                // disabled={true}
                onClick={() => {
                  if (index > 1) {
                    setX(-1000);
                  } else {
                    setX(1000);
                  }
                  navigation.go?.('personal details');
                }}
                className={`step ${
                  id === 'personal details' ? 'active' : ''
                }`}></button>
              {localStorage.getItem('accountTypeSession') &&
                localStorage.getItem('accountTypeSession') != 'Personal' && (
                  <button //business details btn
                    type="button"
                    // disabled={true}
                    onClick={() => {
                      if (index > 2) {
                        setX(-1000);
                      } else if (index < 2) {
                        setX(1000);
                      }
                      navigation.go?.('business details');
                    }}
                    className={`step ${
                      id === 'business details' ? 'active' : ''
                    }`}></button>
                )}
              {localStorage.getItem('accountTypeSession') &&
                localStorage.getItem('accountTypeSession') != 'Personal' && (
                  <button //nok btn
                    type="button"
                    // disabled={true}
                    onClick={() => {
                      if (index > 3) {
                        setX(-1000);
                      } else if (index < 3) {
                        setX(1000);
                      }
                      navigation.go?.('next of kin');
                    }}
                    className={`step ${
                      id === 'next of kin' ? 'active' : ''
                    }`}></button>
                )}
              <button //pin creation btn
                type="button"
                // disabled={true}
                onClick={() => {
                  if (index > 4) {
                    setX(1000);
                  } else if (index < 4) {
                    setX(-1000);
                  }
                  navigation.go?.('create pin');
                }}
                className={`step ${
                  id === 'create pin' ? 'active' : ''
                }`}></button>
            </div>

            {id === 'account type' && (
              <AccountType x={x} setX={setX} next={navigation.next} />
            )}
            {id === 'BVN validation' && (
              <BvnValidate
                x={x}
                setX={setX}
                next={navigation.next}
                previous={navigation.previous}
              />
            )}
            {id === 'personal details' && (
              <PersonalDetails
                x={x}
                setX={setX}
                next={
                  // navigation.next
                  () => {
                    localStorage.getItem('accountTypeSession')?.toString() &&
                    localStorage.getItem('accountTypeSession')?.toString() !=
                      'Personal'
                      ? navigation.go?.('business details')
                      : navigation.go?.('create pin');
                  }
                }
                previous={navigation.previous}
              />
            )}

            {localStorage.getItem('accountTypeSession') &&
              localStorage.getItem('accountTypeSession') != 'Personal' &&
              id === 'business details' && (
                <BusinessDetails
                  x={x}
                  setX={setX}
                  next={navigation.next}
                  previous={navigation.previous}
                />
              )}
            {localStorage.getItem('accountTypeSession') &&
              localStorage.getItem('accountTypeSession') != 'Personal' &&
              id === 'next of kin' && (
                <NextOfKinDetails
                  x={x}
                  setX={setX}
                  next={navigation.next}
                  previous={navigation.previous}
                />
              )}

            {id === 'create pin' && (
              <CreatePin
                x={x}
                setX={setX}
                next={navigation.next}
                previous={navigation.previous}
              />
            )}
          </div>
        </div>
        <div className="sidebar-logout-btn align-self-center">
          <button
            type="button"
            onClick={handleLogOut}
            className="text-nowrap me-4">
            <Icon
              path={mdiLogout}
              title="User Profile"
              size={1}
              horizontal
              style={{ color: '#054b99' }}
            />
            <span>Sign out</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileMultistepForm;
