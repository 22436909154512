/* eslint-disable @typescript-eslint/no-unused-vars */
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { StoreContext } from '../../../mobx_stores/RootStore';
import CashBackCard from './CashBackCard';
import Debt from './Debt';
import Inventory from './Inventory';

const AllBookkeeping = () => {
  const { loansStore } = useContext(StoreContext);
  const { loading } = loansStore;

  const [headerText, setHeaderText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [screen, setScreen] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(screen <= 767);
  const [activeTab, setActiveTab] = useState(0); // Track the active tab

  function toggleMenu() {
    setIsOpen(!isOpen);
  }

  function activeHeader(head: any) {
    setHeaderText(head.target.innerHTML);
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    function handleResize() {
      setScreen(window.innerWidth);
      setMobile(window.innerWidth <= 767);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      {loading ? (
        <div>{/* Loading Skeleton */}</div>
      ) : (
        <div>
          <h4 className="section-head">Bookkeeping</h4>
          <div className="bookkeeping-container">
            <div className="guarantor-list d-flex justify-content-between">
              {mobile &&
                (!isOpen ? (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    onClick={toggleMenu}
                    className="mobile-menu-icon"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faChevronUp}
                    className="mobile-menu-icon"
                  />
                ))}
              <Tabs
                selectedTabClassName="selected"
                onSelect={(index) => setActiveTab(index)} // Track the selected tab
              >
                {mobile ? (
                  <>
                    <TabList
                      className={
                        'd-flex flex-column flex-md-row m-0 p-0 guarantor-heade automatic'
                      }>
                      {!isOpen && (
                        <div className="guarantors-header-link">
                          <h5>{headerText === '' ? 'Invoices' : headerText}</h5>
                        </div>
                      )}
                      <div className={isOpen ? '' : 'hidden-header'}>
                        <Tab
                          className="guarantors-header-link"
                          onClick={(e) => activeHeader(e)}>
                          <h5 className="">Bookkeeping</h5>
                        </Tab>
                        <Tab
                          className="guarantors-header-link"
                          onClick={(e) => activeHeader(e)}>
                          <h5>Cashback</h5>
                        </Tab>
                        <Tab
                          className="guarantors-header-link"
                          onClick={(e) => activeHeader(e)}>
                          <h5>Debt</h5>
                        </Tab>
                        <Tab
                          className="guarantors-header-link"
                          onClick={(e) => activeHeader(e)}>
                          <h5>Inventory</h5>
                        </Tab>
                      </div>
                    </TabList>
                  </>
                ) : (
                  <div className="guarantor-list d-flex justify-content-between">
                    <TabList
                      className={'d-flex m-0 p-0 guarantor-heade automatic'}>
                      <Tab className="guarantors-header-link">
                        <h5 className="m-0 fw-bold">Cashback</h5>
                      </Tab>
                      <Tab className="guarantors-header-link">
                        <h5 className="m-0 fw-bold">Debt</h5>
                      </Tab>
                      <Tab className="guarantors-header-link">
                        <h5 className="m-0 fw-bold">Inventory</h5>
                      </Tab>
                    </TabList>
                    <div className="d-flex align-items-end gap-4">
                      <div>
                        <p className="mb-0">04 FEB 2024</p>
                      </div>
                      <div className="">
                        <button
                          className="btn text-white btn-sm text-center"
                          style={{ backgroundColor: '#6FAB23' }}>
                          <Icon path={mdiPlus} size={1} color="#fff" /> Add new
                          Product
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <TabPanel>
                  <CashBackCard />
                </TabPanel>
                <TabPanel>
                  <Debt />
                </TabPanel>
                <TabPanel>
                  <Inventory />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(AllBookkeeping);
