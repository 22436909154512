import {
  mdiChartBox,
  mdiCircleSmall,
  mdiPlus,
  mdiWalletOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import BaseDirectories from '../../../base directories/BaseDirectories';

const Inventory = () => {
  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '"></span>';
    },
  };
  return (
    <>
      {' '}
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={pagination}
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          450: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
            autoplay: {
              delay: 500,
              disableOnInteraction: false,
            },
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 24,
          },
          1184: {
            slidesPerView: 4,
            spaceBetween: 24,
          },
        }}
        modules={[Pagination]}
        className="lp-career-container mySwiper">
        <SwiperSlide>
          <div className="bg-white pt-2 ps-4">
            <p className="my-3 fw-bold">Total Products</p>
            <div className="d-flex gap-3 mt-3 py-0 mb-lg-4">
              <div className="">
                <h4 className="font-bold d-inline-block text-success">
                  120 <Icon path={mdiPlus} size={1} />
                </h4>
                <p>From Last Month</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="pt-2 ps-4" style={{ backgroundColor: '#FCF0E9' }}>
            <p className="my-3 fw-bold">Total Quantity</p>
            <div className="d-flex gap-3 mt-3 py-0 mb-lg-4">
              <div className="">
                <h4 className="font-bold d-inline-block text-success">
                  120 <Icon path={mdiPlus} size={1} />
                </h4>
                <p>From Last Month</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="bg-white pt-2 ps-4">
            <p className="my-3 fw-bold">Cost Value</p>
            <div className="d-flex gap-3 mt-3 py-0 mb-lg-4">
              <div className="">
                <h4 className="font-bold d-inline-block text-success">
                  N300,400
                </h4>
                <p>From Last Month</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="pt-2 ps-4"
            style={{
              backgroundColor: '#FCF0E9',
              boxShadow:
                '0px 4px 12px rgba(0, 0, 0, 0.1), 0px -4px 12px rgba(0, 0, 0, 0.1), 4px 0px 12px rgba(0, 0, 0, 0.1), -4px 0px 12px rgba(0, 0, 0, 0.1)',
            }}>
            <p className="my-3 fw-bold">Cost Per Unit</p>
            <div className="d-flex gap-3 mt-3 py-0 mb-lg-4">
              <div className="">
                <h4 className="font-bold d-inline-block text-success">N3000</h4>
                <p>From Last Month</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="bg-white table-responsive">
        <table className="table table-striped head-color responsive-table  mb-0">
          <thead
            style={{
              borderBottom: '1px solid black',
            }}>
            <tr>
              <th>Product/Item Name</th>
              <th>Quantity</th>
              <th>Cost By Unit</th>
              <th>Cost Value</th>
              <th>Threshold</th>
              <th>Storage life</th>
              <th>Supplier</th>
              <th>Cart</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Vodka Absolut</td>
              <td>120</td>
              <td>1000</td>
              <td>120,000</td>
              <td>5</td>
              <td>7</td>
              <td>Jmart</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Inventory;
