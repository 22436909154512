/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import {
  mdiChevronRight,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFileDocumentMultipleOutline,
  mdiInformationOutline,
  mdiWallet,
} from '@mdi/js';
import Icon from '@mdi/react';
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MaterialReactTable,
} from 'material-react-table';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { FaAsterisk } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { toast } from 'react-toastify';
import BaseDirectories from '../../../base directories/BaseDirectories';
import { StoreContext } from '../../../mobx_stores/RootStore';
import BillModal from './BillModal';
import BillTransactionModal from './BillTransactionModal';
import AirtimePurchase from './form/AirtimePurchase';
import CableTvPurchase from './form/CableTvPurchase';
import ChangeLimitModal from './form/changeLimitModal';
import DataPurchase from './form/DataPurchase';
import NewElectricityPurchase from './form/NewElectricityPurchase';
import RedirectModal from './RedirectModal';
import ToggleSwitch from './ToggleSwitch';
import TransactionModal from './TransactionModal';
import TransferToBankModal from './TransferToBankModal';
import TransferToWalletModal from './TransferToWalletModal';
import Ussd from './Ussd';

type Transactions = { [key: string]: string | number | undefined }[];

const billPaymentCategory = [
  {
    id: 1,
    name: 'Airtime TopUp',
    icon: 'airtime.png',
  },
  {
    id: 2,
    name: 'Data Bundle',
    icon: 'data.png',
  },
  {
    id: 3,
    name: 'Electricity',
    icon: 'electricity.png',
  },
  {
    id: 4,
    name: 'Cable TV',
    icon: 'cable_tv.png',
  },
];

let rowCount = 0;
let pageCount = 0;
let creditRowCount = 0;
let creditPageCount = 0;
let debitRowCount = 0;
let debitPageCount = 0;
let clicked = '';

const UserWallet: FC = () => {
  const redirect = useNavigate();
  const [toggled, setToggled] = useState(false);
  const [showBalance, setShowBalance] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openWalletTransferModal, setOpenWalletTranferModal] = useState(false);
  const [openBankTransferModal, setOpenBankTranferModal] = useState(false);
  const [openInvestModal, setOpenInvestModal] = useState(false);
  const [openInsureModal, setOpenInsureModal] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState();
  const [billTransactionDetail, setBillTransactionDetail] = useState();
  const [openStatementModal, setOpenStatementModal] = useState(false);

  const [billTransactions, setBillTransactions] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [panel, setPanel] = useState('');

  const { walletStore, billStore, loansStore } = useContext(StoreContext);
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [openBillTransactionModal, setOpenBillTransactionModal] =
    useState(false);
  const {
    loadingData,
    wallet,
    walletAccountDetails,
    loadingWallet,
    multipleWallet,
  } = walletStore;
  const { transactions } = billStore;
  const { loanUserdetails } = loansStore;
  const [openAirtimeModal, setOpenAirtimeModal] = useState(false);
  const [openDataModal, setOpenDataModal] = useState(false);
  const [openPowerModal, setOpenPowerModal] = useState(false);
  const [openCableTvModal, setOpenCableTvModal] = useState(false);
  const [showChangeLimitModal, setShowChangeLimitModal] = useState(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const handleShowVerificationModal = () => {
    setShowChangeLimitModal(true);
  };

  useEffect(() => {
    if (!loadingWallet) {
      walletStore.getWallet();
      walletStore.getAllWalletWithoutLoading();
    }
    walletStore.getAllPaginatedTransactions(
      pagination.pageIndex,
      pagination.pageSize,
    );
    walletStore.setTransactions;
    rowCount = toJS(walletStore?.transactions)?.count;
    pageCount = toJS(walletStore?.transactions)?.maxPages;
  }, []);

  useEffect(() => {
    if (clicked == 'type=debit') {
      setPanel('debit');
      walletStore.getDebitTransactions(
        pagination.pageIndex,
        pagination.pageSize,
      );
      walletStore.setDebitTransactions;
      debitRowCount = toJS(walletStore?.debitTransactions)?.count;
      debitPageCount = toJS(walletStore?.debitTransactions)?.maxPages;
    } else if (clicked == 'type=credit') {
      setPanel('credit');
      walletStore.getCreditTransactions(
        pagination.pageIndex,
        pagination.pageSize,
      );
      walletStore.setCreditTransactions;
      creditRowCount = toJS(walletStore?.creditTransactions)?.count;
      creditPageCount = toJS(walletStore?.creditTransactions)?.maxPages;
    } else if (clicked == '') {
      setPanel('');
      walletStore.getAllPaginatedTransactions(
        pagination.pageIndex,
        pagination.pageSize,
      );
      walletStore.setTransactions;
      rowCount = toJS(walletStore?.transactions)?.count;
      pageCount = toJS(walletStore?.transactions)?.maxPages;
    } else if (clicked == 'type=bill') {
      setPanel('bill');
      billStore.getAllTransaction();
      setBillTransactions(toJS(transactions));
    }
  }, [pagination.pageIndex, pagination.pageSize, clicked]);

  useEffect(() => {
    if (clicked == 'type=debit') {
      setPanel('debit');
      debitRowCount = toJS(walletStore?.debitTransactions)?.count;
      debitPageCount = toJS(walletStore?.debitTransactions)?.maxPages;
    } else if (clicked == 'type=credit') {
      creditRowCount = toJS(walletStore?.creditTransactions)?.count;
      creditPageCount = toJS(walletStore?.creditTransactions)?.maxPages;
    } else if (clicked == '') {
      setPanel('');
      rowCount = toJS(walletStore?.transactions)?.count;
      pageCount = toJS(walletStore?.transactions)?.maxPages;
    } else if (clicked == 'type=bill') {
      setPanel('bill');
      billStore.getAllTransaction();
      setBillTransactions(toJS(transactions));
    }
  }, [
    walletStore.transactions,
    walletStore.creditTransactions,
    walletStore.debitTransactions,
  ]);

  useEffect(() => {
    billStore.getAllTransaction();
    setBillTransactions(toJS(transactions));
    // walletStore.getProvidusNipBanks();
  }, [billStore]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!loadingWallet) {
        walletStore.getWalletWithoutLoading();
        walletStore.getAllWalletWithoutLoading();
      }
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  const detailsParams = (name: string) => {
    clicked = name;
    if (name == 'type=debit') {
      setPanel('debit');
      walletStore.getDebitTransactions(
        pagination.pageIndex,
        pagination.pageSize,
      );
      walletStore.setDebitTransactions;
      debitRowCount = toJS(walletStore?.debitTransactions)?.count;
      debitPageCount = toJS(walletStore?.debitTransactions)?.maxPages;
    } else if (name == 'type=credit') {
      setPanel('credit');
      walletStore.getCreditTransactions(
        pagination.pageIndex,
        pagination.pageSize,
      );
      walletStore.setCreditTransactions;
      creditRowCount = toJS(walletStore?.creditTransactions)?.count;
      creditPageCount = toJS(walletStore?.creditTransactions)?.maxPages;
    } else if (name == '') {
      setPanel('');
      walletStore.getAllPaginatedTransactions(
        pagination.pageIndex,
        pagination.pageSize,
      );
      walletStore.setTransactions;
      rowCount = toJS(walletStore?.transactions)?.count;
      pageCount = toJS(walletStore?.transactions)?.maxPages;
    } else if (name == 'type=bill') {
      setPanel('bill');
      billStore.getAllTransaction();
      setBillTransactions(toJS(transactions));
    }
  };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  const showComingSoon = () => {
    toast.success('Coming soon. Please try again later.');
  };

  const handleBillModal = (category: string) => {
    if (category === 'Airtime TopUp') {
      setOpenAirtimeModal(true);
    }
    if (category === 'Data Bundle') {
      setOpenDataModal(true);
    }
    if (category === 'Electricity') {
      setOpenPowerModal(true);
    }
    if (category === 'Cable TV') {
      setOpenCableTvModal(true);
    }
  };

  useEffect(() => {
    if (!showModal) {
      walletStore.setBillField({});
    }
  }, [showModal]);

  const handleRowClick = (data: any) => {
    setTransactionDetail(data);
    setOpenTransactionModal(true);
  };

  const handleBillRowClick = (data: any) => {
    setBillTransactionDetail(data);
    setOpenBillTransactionModal(true);
  };

  const billData =
    billTransactions &&
    billTransactions?.map((tr: any, i: number) => ({
      id: i + 1,
      name: tr.fromAccountName,
      amount: tr.amount,
      debitStatus: tr.debitStatus,
      serviceType: tr.serviceType,
      debitReference: tr.debitReference,
      transactionReference: tr.transactionReference,
      narration: tr.narration,
      phone: tr.phone,
      date: tr.updatedAt,
      transactionStatus: tr.transactionStatus,
    }));

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        accessorKey: 'createdAt',
        id: 'createdAt',
        header: 'Time',
        Cell: ({ cell }) => (
          <span>{moment(cell.getValue<number>()).format('MM-D-YYYY')}</span>
        ),
      },
      {
        accessorKey: 'debit',
        id: 'debit',
        header: 'Amount',
        Cell: ({ cell, row }) => (
          <span>
            ₦{' '}
            {cell.getValue<number>() > 0
              ? new Intl.NumberFormat('en-US', {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(cell.getValue<number>())
              : new Intl.NumberFormat('en-US', {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(Number(row.original['credit']))}
          </span>
        ),
      },
      {
        accessorKey: 'transactionStatus',
        id: 'transactionStatus',
        header: 'Status',
        Cell: ({ cell }) =>
          cell.getValue<string>() == 'Successful' ? (
            <span className="text-success">{cell.getValue<string>()}</span>
          ) : (
            <span className="text-danger">{cell.getValue<string>()}</span>
          ),
      },
    ],
    [],
  );

  const billColumns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        accessorKey: 'id',
        id: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'narration',
        id: 'narration',
        header: 'Narration',
        Cell: ({ cell }) => (
          <span className="text-capitalize">{cell.getValue<string>()}</span>
        ),
      },

      {
        accessorKey: 'date',
        id: 'date',
        header: 'Time',
        Cell: ({ cell }) => (
          <span>{moment(cell.getValue<number>()).format('MM-D-YYYY')}</span>
        ),
      },
      {
        accessorKey: 'amount',
        id: 'amount',
        header: 'Amount',
        Cell: ({ cell }) => (
          <span>
            ₦{' '}
            {new Intl.NumberFormat('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(cell.getValue<number>())}
          </span>
        ),
      },
    ],
    [],
  );

  const handleAccountUpgrade = () => {
    // Check if the account has business details
    if (wallet?.type === 'Personal') {
      if (toJS(loanUserdetails?.organizationDetails) !== undefined) {
        const payload = {
          walletIdAccountNumber: wallet?.walletIdAccountNumber,
          firstName: loanUserdetails?.organizationDetails?.businessName,
          lastName: '',
        };
        walletStore.upgradeWallet(payload);
      } else {
        redirect('/dashboard/wallet/user_personal_details');
      }
    }

    if (wallet?.type === 'Business') {
      const payload = {
        walletIdAccountNumber: wallet?.walletIdAccountNumber,
        firstName: walletAccountDetails?.firstName,
        lastName: walletAccountDetails?.lastName,
      };
      walletStore.upgradeWallet(payload);
    }
  };

  return (
    <>
      <div className="user-wallet">
        {openTransactionModal && (
          <TransactionModal
            setOpenTransactionModal={setOpenTransactionModal}
            Data={transactionDetail}
            walletAccountNumber={wallet?.walletIdAccountNumber}
          />
        )}
        {openBillTransactionModal && (
          <BillTransactionModal
            setOpenBillTransactionModal={setOpenBillTransactionModal}
            Data={billTransactionDetail}
          />
        )}

        {/* {(loadingData || submitting || billStore.loadingData) && <Spinner />} */}
        {openModal && <Ussd setOpenModal={setOpenModal} />}
        {openWalletTransferModal && (
          <TransferToWalletModal
            setOpenWalletTranferModal={setOpenWalletTranferModal}
          />
        )}
        {openBankTransferModal && (
          <TransferToBankModal
            setOpenBankTransferModal={setOpenBankTranferModal}
          />
        )}
        {openInvestModal && (
          <RedirectModal
            image={`${BaseDirectories.IMAGES_DIR}/arrowFoward.png`}
            imageStyle={{ width: '20%' }}
            heading="Invest"
            body="Transfer money to invest on our packages"
            button={true}
            btnText1="Start Investing"
            route="/dashboard/invest"
            close={() => setOpenInvestModal(false)}
          />
        )}
        {openInsureModal && (
          <RedirectModal
            image={`${BaseDirectories.IMAGES_DIR}/arrowFoward.png`}
            imageStyle={{ width: '20%' }}
            heading="Get Insured"
            body="Get insured with funds from your wallet!"
            button={true}
            btnText1="Get Insured"
            onClick={showComingSoon}
            // route="/dashboard/insurance"
            close={() => setOpenInsureModal(false)}
          />
        )}
        {openTransferModal && (
          <RedirectModal
            image={`${BaseDirectories.IMAGES_DIR}/greenarrowforward.svg`}
            imageStyle={{ width: '20%' }}
            heading="Bank Transfer"
            body="Transfer money to the account number below to fund your wallet"
            close={() => setOpenTransferModal(false)}
            DataObj={toJS(multipleWallet)}
            AccountDetails={walletAccountDetails}
          />
        )}
        {openStatementModal && (
          <RedirectModal
            image={`${BaseDirectories.IMAGES_DIR}/greenarrowforward.svg`}
            imageStyle={{ width: '20%' }}
            heading="E-Statement"
            body="Get your transactions statement in your inbox"
            close={() => setOpenStatementModal(false)}
            button={true}
            btnText3="Process Statement"
          />
        )}
        {showModal && (
          <BillModal showModal={showModal} setShowModal={setShowModal} />
        )}
        {openAirtimeModal && (
          <AirtimePurchase setOpenAirtimePurchaseModal={setOpenAirtimeModal} />
        )}
        {openDataModal && (
          <DataPurchase setOpenDataPurchaseModal={setOpenDataModal} />
        )}
        {openPowerModal && (
          <NewElectricityPurchase
            setOpenPowerPurchaseModal={setOpenPowerModal}
          />
        )}
        {openCableTvModal && (
          <CableTvPurchase setOpenCableTvPurchaseModal={setOpenCableTvModal} />
        )}
        {showChangeLimitModal && (
          <ChangeLimitModal
            setShowChangeLimitModal={setShowChangeLimitModal}
            transactionLimit={toJS(wallet)?.transactionLimit}
            dailyTransactionLimit={toJS(wallet)?.dailyTransactionLimit}
          />
        )}
        <h4 className="section-head">Wallet</h4>
        <div className="container wlt-cont">
          <div className="col-5 col-md left-section">
            <div className="">
              <div
                className="row upper-container  px-4 mb-5"
                style={{
                  backgroundImage: `url("${BaseDirectories.IMAGES_DIR}/walletballance.png")`,
                }}>
                <div className="upper-group mb-3 mt-3">
                  <div className="m-0 p-0 title-group flex-wrap">
                    <Icon path={mdiWallet} size={1} />
                    <p className="mt-3">Wallet Balance</p>
                    {!loadingData &&
                      wallet &&
                      (wallet.active === true ? (
                        <h6></h6>
                      ) : wallet.active === false ? (
                        <h6 className="wallet-status badge bg-danger mb-1">
                          Deactivated
                        </h6>
                      ) : (
                        <h6></h6>
                      ))}
                    {!loadingData &&
                      wallet &&
                      (wallet.PND === false ? (
                        <h6></h6>
                      ) : wallet.PND === true ? (
                        <h6 className="wallet-status badge bg-danger mb-1">
                          No Debits Allowed
                        </h6>
                      ) : (
                        <h6></h6>
                      ))}
                    {!loadingData &&
                      wallet &&
                      (wallet.PNC === false ? (
                        <h6></h6>
                      ) : wallet.PNC === true ? (
                        <h6 className="wallet-status badge bg-danger mb-1">
                          No Credits Allowed
                        </h6>
                      ) : (
                        <h6></h6>
                      ))}
                  </div>

                  <div className=" bal-visibility">
                    <button
                      className=""
                      onClick={() => setShowBalance(!showBalance)}>
                      <Icon
                        path={showBalance ? mdiEyeOffOutline : mdiEyeOutline}
                        size={1}
                      />
                    </button>
                  </div>
                </div>
                <div className="balance p-0 mb-3">
                  {mdiEyeOffOutline && showBalance ? (
                    toJS(multipleWallet) && toJS(multipleWallet)?.length > 0 ? (
                      toJS(multipleWallet)?.map(
                        (walletData: any, index: number) => (
                          <div key={index}>
                            {/* <small>Bal {index + 1}: </small> */}
                            <span className="wallet-bal">
                              ₦{' '}
                              {walletData?.availableBalance != 'NaN' ||
                              walletData?.availableBalance != null ||
                              walletData?.availableBalance != undefined
                                ? walletData?.availableBalance
                                  ? new Intl.NumberFormat('en-US', {
                                      style: 'decimal',
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(walletData?.availableBalance)
                                  : '0.00'
                                : '0.00'}
                            </span>
                          </div>
                        ),
                      )
                    ) : (
                      <h4
                        style={{ letterSpacing: '1px' }}
                        className="wallet-status badge bg-danger text-white p-2">
                        Wallet does not exist
                      </h4>
                    )
                  ) : (
                    <h4 style={{ height: '40px' }}>
                      <FaAsterisk className="no-show-balance p-0 me-1" />
                      <FaAsterisk className="no-show-balance p-0 me-1" />
                      <FaAsterisk className="no-show-balance p-0 me-1" />
                      <FaAsterisk className="no-show-balance p-0 " />
                    </h4>
                  )}
                </div>
                <div>
                  <ToggleSwitch
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setToggled(e.target.checked)
                    }
                  />
                </div>

                {!toggled ? (
                  <div>
                    {wallet?.type === 'Personal' && (
                      <div
                        className="button-group mb-3"
                        onClick={() => {
                          walletAccountDetails?.firstName !== undefined
                            ? handleAccountUpgrade()
                            : toast.error(
                                'You do not have wallet account details, kindly complete your profile, fill in your next of Kin details and ensure your bvn is correct',
                              );
                        }}>
                        <div className=" p-0 title-group">
                          <img
                            src={`${BaseDirectories.IMAGES_DIR}/vector5.png`}
                            alt=""
                            style={{
                              width: '25px',
                              height: '25px',
                              paddingLeft: '6px',
                            }}
                          />
                          <p className="mt-3">Upgrade To Business</p>
                        </div>
                        <div className=" justify-content-end bal-visibility">
                          <Icon
                            path={mdiChevronRight}
                            size={1.2}
                            className="mt-0"
                          />
                        </div>
                      </div>
                    )}

                    {wallet?.type === 'Business' && (
                      <div
                        className="button-group mb-3"
                        onClick={() => {
                          walletAccountDetails?.firstName !== undefined
                            ? handleAccountUpgrade()
                            : toast.error(
                                'You do not have wallet account details, kindly complete your profile, fill in your next of Kin details and ensure your bvn is correct',
                              );
                        }}>
                        <div className=" p-0 title-group">
                          <img
                            src={`${BaseDirectories.IMAGES_DIR}/vector5.png`}
                            alt=""
                            style={{
                              width: '25px',
                              height: '25px',
                              paddingLeft: '6px',
                              rotate: '180deg',
                            }}
                          />
                          <p className="mt-3">Downgrade To Personal</p>
                        </div>
                        <div className=" justify-content-end bal-visibility">
                          <Icon
                            path={mdiChevronRight}
                            size={1.2}
                            className="mt-0"
                          />
                        </div>
                      </div>
                    )}
                    <div
                      className="button-group mb-3"
                      onClick={() => handleShowVerificationModal()}>
                      <div className=" p-0 title-group">
                        <img
                          height={25}
                          src={`${BaseDirectories.IMAGES_DIR}/unlocked.png`}
                          alt=""
                        />
                        <p className="mt-3">Change Transfer Limit</p>
                      </div>
                      <div className=" justify-content-end bal-visibility">
                        <Icon
                          path={mdiChevronRight}
                          size={1.2}
                          className="mt-0"
                        />
                      </div>
                    </div>

                    <div
                      className="button-group mb-3"
                      onClick={() => {
                        walletAccountDetails?.firstName !== undefined
                          ? setOpenTransferModal(true)
                          : toast.error(
                              'You do not have wallet account details, kindly complete your profile, fill in your next of Kin details and ensure your bvn is correct',
                            );
                      }}>
                      <div className=" p-0 title-group">
                        <img
                          src={`${BaseDirectories.IMAGES_DIR}/transferIcon.png`}
                          alt=""
                        />
                        <p className="mt-3">Bank Transfer</p>
                      </div>
                      <div className=" justify-content-end bal-visibility">
                        <Icon
                          path={mdiChevronRight}
                          size={1.2}
                          className="mt-0"
                        />
                      </div>
                    </div>

                    <div className="button-group" onClick={showComingSoon}>
                      <div className="p-0 title-group">
                        <img
                          src={`${BaseDirectories.IMAGES_DIR}/fundIcon.png`}
                          alt=""
                        />
                        <p className="mt-3">Fund wallet with card</p>
                      </div>
                      <div className=" justify-content-end bal-visibility">
                        <Icon
                          path={mdiChevronRight}
                          size={1.2}
                          className="mt-0"
                        />
                      </div>
                    </div>

                    <div
                      className="button-group mt-3 mb-5"
                      onClick={() => setOpenStatementModal(true)}>
                      <div className="p-0 title-group">
                        <Icon
                          path={mdiFileDocumentMultipleOutline}
                          style={{ color: '#6993C2' }}
                          size={1.1}
                          className="mt-0"
                        />
                        <p className="mt-3">Generate E-Statement</p>
                      </div>
                      <div className="justify-content-end bal-visibility">
                        <Icon
                          path={mdiChevronRight}
                          size={1.2}
                          className="mt-0"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      className="button-group  mb-3 mt-3"
                      onClick={() => setOpenInvestModal(true)}>
                      <div className="  p-0 title-group">
                        <img
                          src={`${BaseDirectories.IMAGES_DIR}/investImage.png`}
                          alt=""
                          // style={{ width: '10%' }}
                        />
                        <p className="mt-3">Invest</p>
                      </div>
                      <div className=" justify-content-end bal-visibility">
                        <Icon
                          path={mdiChevronRight}
                          size={1.2}
                          className="mt-0"
                        />
                      </div>
                    </div>
                    <div
                      className="button-group"
                      onClick={() => setOpenInsureModal(true)}>
                      <div className=" p-0 title-group">
                        <img
                          src={`${BaseDirectories.IMAGES_DIR}/insuranceImage.png`}
                          alt=""
                        />
                        <p className="mt-3">Insurance</p>
                      </div>
                      <div className=" justify-content-end bal-visibility">
                        <Icon
                          path={mdiChevronRight}
                          size={1.2}
                          className="mt-0"
                        />
                      </div>
                    </div>

                    <div
                      className="button-group mt-3"
                      onClick={() => setOpenBankTranferModal(true)}>
                      <div className="   p-0 title-group">
                        <img
                          src={`${BaseDirectories.IMAGES_DIR}/fundIcon.png`}
                          alt=""
                        />
                        <p className="mt-3">Bank Account</p>
                      </div>
                      <div className=" justify-content-end bal-visibility">
                        <Icon
                          path={mdiChevronRight}
                          size={1.2}
                          className="mt-0"
                        />
                      </div>
                    </div>

                    <div
                      className="button-group mt-3 mb-5"
                      onClick={() => setOpenWalletTranferModal(true)}>
                      <div className="   p-0 title-group">
                        <img
                          src={`${BaseDirectories.IMAGES_DIR}/fundIcon.png`}
                          alt=""
                        />
                        <p className="mt-3">Another Wallet</p>
                      </div>
                      <div className=" justify-content-end bal-visibility">
                        <Icon
                          path={mdiChevronRight}
                          size={1.2}
                          className="mt-0"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* BILL PAYMENT */}
            </div>
            <div className="row lower-container m-0">
              <h4>RECHARGE & PAYBILLS</h4>
              <div className="bills-group">
                <div className="row paybills-container">
                  {billPaymentCategory.map((category: any, index: number) => (
                    <div
                      key={index}
                      role="button"
                      // className="col-6 col-sm-6 paybills-group"
                      className="custom-col-lg-1-5 col-md-4 col-sm-4 paybills-group"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleBillModal(category.name)}>
                      <div className="bill-card">
                        <img
                          src={`${BaseDirectories.IMAGES_DIR}/${category.icon}`}
                          alt=""
                          style={{
                            display: 'flex',
                            width: '80%',
                            maxWidth: '100px',
                            minHeight: '50px',
                            height: 'auto',
                          }}
                        />
                      </div>
                      <p className="card-title">{category.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-7 col-md  m-0 right-section">
            <Tabs selectedTabClassName="selected-tab ">
              <div className="col-md-12">
                <div
                  className="d-flex justify-content-center align-items-center gap-2"
                  style={{
                    background: 'aliceblue',
                    padding: '8px 10px',
                    borderRadius: '10px',
                  }}>
                  <Icon
                    path={mdiInformationOutline}
                    size={1.2}
                    color="#676767"
                  />
                  <p
                    className="mb-0"
                    style={{ fontSize: '13px', lineHeight: '20px' }}>
                    Click on row to view more.
                  </p>
                </div>
              </div>
              <div className="header d-flex mt-5 px-4">
                <TabList className={'d-flex m-0 p-0 list-container automatic'}>
                  <Tab
                    className="history-header wallet-tab"
                    onClick={() => detailsParams('')}>
                    All Transactions
                  </Tab>
                  <Tab
                    className="history-header wallet-tab"
                    onClick={() => detailsParams('type=debit')}>
                    Transferred
                  </Tab>
                  <Tab
                    className="history-header wallet-tab"
                    onClick={() => detailsParams('type=credit')}>
                    Received
                  </Tab>
                  {/*
                  <Tab
                    className="history-header wallet-tab"
                    onClick={() => detailsParams('type=bill')}
                  >
                    Bill Payment
                  </Tab> */}
                </TabList>
              </div>
              <TabPanel>
                {panel === '' && (
                  <MaterialReactTable
                    manualPagination
                    columns={columns}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [
                        5, 10, 15, 25, 50, 100, 250, 500, 1000,
                      ],
                      showFirstButton: true,
                      showLastButton: true,
                    }}
                    data={
                      toJS(walletStore?.transactions)?.transaction &&
                      toJS(walletStore?.transactions)?.transaction?.length > 0
                        ? toJS(walletStore?.transactions)?.transaction
                        : []
                    }
                    muiTableBodyRowProps={({ row }) => ({
                      onClick: (e) => {
                        handleRowClick(
                          toJS(walletStore?.transactions)?.transaction[row.id],
                        );
                      },
                      sx: {
                        cursor: 'pointer',
                      },
                    })}
                    onPaginationChange={setPagination}
                    positionGlobalFilter="right"
                    globalFilterFn="contains"
                    positionToolbarAlertBanner="bottom"
                    enableRowNumbers
                    rowCount={rowCount}
                    pageCount={pageCount}
                    rowNumberMode="static"
                    initialState={{
                      pagination: { pageSize: 10, pageIndex: 0 },
                      density: 'spacious',
                      showGlobalFilter: true,
                    }}
                    state={{
                      pagination,
                    }}
                  />
                )}
              </TabPanel>
              <TabPanel>
                {panel === 'debit' && (
                  <MaterialReactTable
                    manualPagination
                    columns={columns}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [
                        5, 10, 15, 25, 50, 100, 250, 500, 1000,
                      ],
                      showFirstButton: true,
                      showLastButton: true,
                    }}
                    data={
                      toJS(walletStore?.debitTransactions)?.transaction &&
                      toJS(walletStore?.debitTransactions)?.transaction
                        ?.length > 0
                        ? toJS(walletStore?.debitTransactions)?.transaction
                        : []
                    }
                    muiTableBodyRowProps={({ row }) => ({
                      onClick: (e) => {
                        handleRowClick(
                          toJS(walletStore?.debitTransactions)?.transaction[
                            row.id
                          ],
                        );
                      },
                      sx: {
                        cursor: 'pointer',
                      },
                    })}
                    onPaginationChange={setPagination}
                    positionGlobalFilter="right"
                    globalFilterFn="contains"
                    positionToolbarAlertBanner="bottom"
                    enableRowNumbers
                    rowCount={debitRowCount}
                    pageCount={debitPageCount}
                    rowNumberMode="static"
                    initialState={{
                      pagination: { pageSize: 10, pageIndex: 0 },
                      density: 'spacious',
                      showGlobalFilter: true,
                    }}
                    state={{
                      pagination,
                    }}
                  />
                )}
              </TabPanel>
              <TabPanel>
                {panel === 'credit' && (
                  <MaterialReactTable
                    manualPagination
                    columns={columns}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [
                        5, 10, 15, 25, 50, 100, 250, 500, 1000,
                      ],
                      showFirstButton: true,
                      showLastButton: true,
                    }}
                    data={
                      toJS(walletStore?.creditTransactions)?.transaction &&
                      toJS(walletStore?.creditTransactions)?.transaction
                        ?.length > 0
                        ? toJS(walletStore?.creditTransactions)?.transaction
                        : []
                    }
                    muiTableBodyRowProps={({ row }) => ({
                      onClick: (e) => {
                        handleRowClick(
                          toJS(walletStore?.creditTransactions)?.transaction[
                            row.id
                          ],
                        );
                      },
                      sx: {
                        cursor: 'pointer',
                      },
                    })}
                    onPaginationChange={setPagination}
                    positionGlobalFilter="right"
                    globalFilterFn="contains"
                    positionToolbarAlertBanner="bottom"
                    enableRowNumbers
                    rowCount={creditRowCount}
                    pageCount={creditPageCount}
                    rowNumberMode="static"
                    initialState={{
                      pagination: { pageSize: 10, pageIndex: 0 },
                      density: 'spacious',
                      showGlobalFilter: true,
                    }}
                    state={{
                      pagination,
                    }}
                  />
                )}
              </TabPanel>
              <TabPanel>
                {panel === 'bill' && (
                  <MaterialReactTable
                    columns={billColumns}
                    data={billData ? billData : []}
                    muiTableBodyRowProps={({ row }) => ({
                      onClick: (e) => {
                        handleBillRowClick(billData[row.id]);
                      },
                      sx: {
                        cursor: 'pointer',
                      },
                    })}
                  />
                )}
              </TabPanel>
            </Tabs>
          </div>
        </div>
        {/* <div className="row lower-container m-0">
          <h4>RECHARGE & PAYBILLS</h4>
          <div className="bills-group">
            <div className="row paybills-container">
              {billPaymentCategory.map((category: any, index: number) => (
                <div
                  key={index}
                  role="button"
                  // className="col-6 col-sm-6 paybills-group"
                  className="custom-col-lg-1-5 col-md-4 col-sm-4 paybills-group"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleBillModal(category.name)}>
                  <div className="bill-card">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/${category.icon}`}
                      alt=""
                      style={{
                        display: 'flex',
                        width: '80%',
                        maxWidth: '100px',
                        minHeight: '50px',
                        height: 'auto',
                      }}
                    />
                  </div>
                  <p className="card-title">{category.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default observer(UserWallet);
