const BaseDirectories = {
  /**
   ** This is a secret
   */
  CFT_PW:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfaWQiOiJXLXN0ZXJpbCIsImRldGFpbHMiOltdLCJtZXRhZGF0YSI6IiIsImlhdCI6MTcyNzI1NDYwM30.iOe4HkLV5M9aLl7Z8wvsrCrdP8OiTyFseE9CNC7Hx6w',

  /**
   ** The base URL of the application
   */
  BASE_URL: process.env.REACT_APP_BASEURL,

  FCP: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfaWQiOiJXLXN0ZXJpbCIsImRldGFpbHMiOltdLCJtZXRhZGF0YSI6IiIsImlhdCI6MTcyODUyMTYzMH0.EzakdN4T3IKrRZcCzaZJ2YW3x5E49cl5P4VJ_O4fv-w',

  APP_ENV: process.env.REACT_APP_ENV,

  /**
   * The base URL of the Server API
   */
  API_BASE_URL: process.env.REACT_APP_API_BASEURL,

  TOKEN: window.sessionStorage.getItem('accessToken'),

  headers: {
    accept: 'application/json',
    Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`,
    'Content-Type': 'application/json',
  },

  /** Directories and folders. */
  IMAGES_DIR: '/images',
  LOGOS_DIR: '/images/logos',
  ICONS_DIR: '/images/icons',
  TEAM_DIR: '/images/team',
};

export default BaseDirectories;
