import { mdiArrowLeft } from '@mdi/js';
import Icon from '@mdi/react';
import { FC, useContext, useState } from 'react';
import { useStep } from 'react-hooks-helper';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { StoreContext } from '../../../mobx_stores/RootStore';
import BankDetails from './LoansInfoCollection/BankDetails';
import BusinessDetails from './LoansInfoCollection/BusinessDetails';
import DocumentUpload from './LoansInfoCollection/DocumentUpload';
import PersonalDetails from './LoansInfoCollection/LoansPersonalDetails';
import NextOfKin from './LoansInfoCollection/NextOfKin';

interface Step {
  id: string;
}

const UserDetailsMultiform: FC = () => {
  const prevRoute = useLocation();
  const [x, setX] = useState(0);
  const { index, step, navigation } = useStep({
    steps: [
      { id: 'personal details' },
      { id: 'business details' },
      { id: 'next of kin details' },
      { id: 'bank details' },
      { id: 'document upload' },
    ],
  });
  const { id } = step as Step;
  const { loansStore } = useContext(StoreContext);
  const { loanUserdetails } = loansStore;
  return (
    <>
      <div
        className="profile-multistep pt-0"
        style={{ backgroundColor: '#EFF0F6' }}>
        {prevRoute?.pathname !== '/dashboard/wallet/user_personal_details' && (
          <div className="section-head">
            {' '}
            {loanUserdetails?.organizationDetails?.shariaCom
              ? 'Facility'
              : 'Loans'}
          </div>
        )}
        <div className="">
          <div className="row justify-content-center">
            <div className="col-sm-10">
              {/* <div className="logo text-center">
              <img src={`${BaseDirectories.LOGOS_DIR}/logo1.png`} alt="" />
              </div> */}
              <div className="row justify-content-between">
                <div
                  className="d-none d-md-block back"
                  style={{
                    marginTop: '10px',
                    color: '#054b99',
                  }}>
                  <Link to="/dashboard/loans">
                    <Icon
                      path={mdiArrowLeft}
                      size={1}
                      style={{
                        marginLeft: '-90px',
                      }}
                    />
                    Back
                  </Link>
                </div>
                <div className="step-progress mb-4">
                  <button
                    type="button"
                    disabled={true}
                    onClick={() => {
                      if (index > 0) {
                        setX(-1000);
                      } else {
                        setX(1000);
                      }
                      navigation.go?.('personal details');
                    }}
                    className={`step ${
                      id === 'personal details' ? 'active' : ''
                    }`}></button>
                  <button
                    type="button"
                    disabled={true}
                    onClick={() => {
                      if (index > 1) {
                        setX(-1000);
                      } else if (index < 1) {
                        setX(1000);
                      }
                      navigation.go?.('business details');
                    }}
                    className={`step ${
                      id === 'business details' ? 'active' : ''
                    }`}></button>
                  <button
                    type="button"
                    disabled={true}
                    onClick={() => {
                      if (index > 1) {
                        setX(-1000);
                      } else if (index < 1) {
                        setX(1000);
                      }
                      navigation.go?.('next of kin details');
                    }}
                    className={`step ${
                      id === 'next of kin details' ? 'active' : ''
                    }`}></button>
                  <button
                    type="button"
                    // disabled={true}
                    onClick={() => {
                      if (index > 1) {
                        setX(-1000);
                      } else if (index < 1) {
                        setX(1000);
                      }
                      navigation.go?.('bank details');
                    }}
                    className={`step ${
                      id === 'bank details' ? 'active' : ''
                    }`}></button>
                  <button
                    type="button"
                    // disabled={true}
                    onClick={() => {
                      if (index < 0) {
                        setX(-1000);
                      } else {
                        setX(1000);
                      }
                      navigation.go?.('document upload');
                    }}
                    className={`step ${
                      id === 'document upload' ? 'active' : ''
                    }`}></button>
                </div>
              </div>

              {id === 'personal details' && (
                <PersonalDetails x={x} setX={setX} next={navigation.next} />
              )}
              {id === 'business details' && (
                <BusinessDetails
                  x={x}
                  setX={setX}
                  next={navigation.next}
                  previous={navigation.previous}
                />
              )}
              {id === 'next of kin details' && (
                <NextOfKin
                  x={x}
                  setX={setX}
                  next={navigation.next}
                  previous={navigation.previous}
                />
              )}
              {id === 'bank details' && (
                <BankDetails
                  x={x}
                  setX={setX}
                  next={navigation.next}
                  previous={navigation.previous}
                />
              )}
              {id === 'document upload' && (
                <DocumentUpload
                  x={x}
                  setX={setX}
                  previous={navigation.previous}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetailsMultiform;
