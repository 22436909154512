import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../components/ui/Button';
import { v4 as uuidv4 } from 'uuid';
import BaseDirectories from '../../../../base directories/BaseDirectories';
import Icon from '@mdi/react';
import { mdiDeleteEmpty, mdiPencilOutline } from '@mdi/js';
import ReactToPrint from 'react-to-print';
import { StoreContext } from '../../../../mobx_stores/RootStore';
import { observer } from 'mobx-react-lite';

interface ListItem {
  id: string;
  description: string;
  quantity: number;
  price: number;
  amount: number;
}

const InvoiceFormThree = () => {
  const navigate = useNavigate();
  // const { template } = useParams();

  const { invoiceStore, profileStore } = useContext(StoreContext);
  // const {
  //   createInvoice,
  //   updateInvoice,
  //   invoiceDetails,
  //   setInvoiceDetails,
  //   submitting,
  //   message,
  // } = invoiceStore;

  const { businessDetails } = profileStore;

  // const [businessDetails, setBusinessDetails] = useState<any>({
  //   businessType: '',
  //   businessName: '',
  //   businessAddress: '',
  //   country: 'Nigeria',
  //   state: '',
  //   city: '',
  // });

  // console.debug(invoiceStore.invoiceDetails.companyName);

  // Consolidate state into a single object for related fields
  const [invoiceDetails, setInvoiceDetails] = useState({
    companyName: '',
    subject: '',
    companyAddress: '',
    companyEmail: '',
    companyPhone: '',
    taxNumber: '',
    customerPhone: '',
    // companyAddress: '',
    companyWebsite: '',
    companyCity: '',
    companyState: '',
    customerCity: '',
    customerState: '',
    customer: '',
    invoiceNumber: '',
    invoiceDate: '',
    notes: '',
    companyLogo: '',
    paymentDue: '',
    //
    termsAndConditions: '',
    email: '',
    phoneNumber: '',
    paymentDueDate: '',
    website: '',
    reference: '',
  });

  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0);
  const [list, setList] = useState<ListItem[]>([]);
  const [showInvoice, setShowInvoice] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [taxCalc, setTaxCalc] = useState(0);
  const [total, setTotal] = useState(0);

  const componentRef = useRef(null);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setInvoiceDetails({ ...invoiceDetails, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!description.trim()) {
      alert('Description is required.');
      return;
    }
    if (quantity <= 0) {
      alert('Quantity must be a positive number.');
      return;
    }
    if (price <= 0) {
      alert('Price must be a positive number.');
      return;
    }
    const newItem: ListItem = {
      id: uuidv4(),
      description,
      quantity,
      price,
      amount,
    };
    setList((prevList) => [...prevList, newItem]);
    setDescription('');
    setQuantity(0);
    setPrice(0);
    setAmount(0);
  };

  // Combined useEffect for amount, subtotal, tax, and total calculations
  useEffect(() => {
    setAmount(quantity * price);

    const newSubTotal = list.reduce((sum, item) => sum + item.amount, 0);
    setSubTotal(newSubTotal);

    const calculatedTax = newSubTotal * 0.1;
    setTaxCalc(calculatedTax);

    setTotal(newSubTotal + calculatedTax);
  }, [quantity, price, list]);

  const handleDeleteItem = (id: string) => {
    setList(list.filter((item) => item.id !== id));
  };

  const editRow = (id: string) => {
    const editingRow = list.find((item) => item.id === id);
    if (editingRow) {
      setList(list.filter((item) => item.id !== id));
      setDescription(editingRow.description);
      setQuantity(editingRow.quantity);
      setPrice(editingRow.price);
    }
  };

  const handleClose = () => {
    navigate('/dashboard/invoices/create_invoice');
  };

  return (
    <>
      <main>
        <h4 className="section-head">Create Invoice</h4>
        {showInvoice ? (
          <>
            <div
              className="guarantor-list invoice-form-details col-10 mx-auto"
              ref={componentRef}>
              <header>
                <div className="d-flex justify-content-between p-5">
                  <div className="row gap-2">
                    <div className="text-start">
                      <h2>INVOICE</h2>
                    </div>
                    <div className="col-md-4 text-start">
                      <p className="m-0">Billed to </p>
                      <h4>{invoiceDetails.customer}</h4>
                      <span>{invoiceDetails.companyAddress}</span>
                      <br />
                      <span>
                        {invoiceDetails.companyName},
                        {invoiceDetails.companyAddress}
                      </span>
                      <p>{businessDetails.companyPhone}</p>
                    </div>
                  </div>
                  <div className="text-end">
                    <img
                      src={`${BaseDirectories.LOGOS_DIR}/Logo2.png`}
                      alt=""
                      style={{ width: '100%', height: '30px' }}
                    />
                    <p className="mb-0">{invoiceDetails.companyAddress}</p>
                    <p className="mb-0">
                      {businessDetails.companyCity},
                      {businessDetails.companyState}
                    </p>
                    <p className="mb-0">{businessDetails.taxNumber}</p>
                  </div>
                </div>
              </header>
              <section className="bg-white rounded-2 d-flex">
                <div className="row p-5 pt-0 col-3 gap-2 text-start">
                  <div className="">
                    <p className="m-0 fw-bold">Invoice # </p>
                    <h6 className="m-0">{invoiceDetails.invoiceNumber}</h6>
                  </div>
                  <div className="">
                    <p className="m-0 fw-bold">Invoice Date </p>
                    <h6 className="m-0">{invoiceDetails.invoiceDate}</h6>
                  </div>
                  <div className="">
                    <p className="m-0 fw-bold">Reference </p>
                    <h6 className="m-0">{invoiceDetails.reference}</h6>
                  </div>{' '}
                  <div className="">
                    <p className="m-0 fw-bold">Due Date </p>
                    <h6 className="m-0">{invoiceDetails.paymentDueDate}</h6>
                  </div>
                </div>
                <div className="row col-9">
                  <div className="bg-white table-responsive border rounded-4 ">
                    <table className="table responsive-table mb-0">
                      <thead>
                        <tr className="fw-bold">
                          <td className="text-start">Service</td>
                          <td className="">Qty</td>
                          <td className="">Rate</td>
                          <td className="">Line Total</td>
                        </tr>
                      </thead>

                      <tbody>
                        {list.map(
                          ({ id, description, quantity, price, amount }) => (
                            <tr
                              key={id}
                              className=""
                              style={{
                                borderTop: '1px solid grey',
                                borderBottom: '1px solid grey',
                              }}>
                              <td className="text-start fw-semibold">
                                {description}
                              </td>
                              <td>{quantity}</td>
                              <td>{price}</td>
                              <td>₦{amount.toFixed(2)}</td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </table>

                    <div className="d-flex">
                      <div className="text-start mt-4 col-12">
                        <div className="p-2">
                          <div className="d-flex justify-content-between">
                            <span>Subtotal: </span>{' '}
                            <span>₦{subTotal.toLocaleString()}</span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span>Tax(10%): </span>{' '}
                            <span>₦{taxCalc.toLocaleString()}</span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="">Total: </span>
                            <span> ₦{total.toLocaleString()}</span>
                          </div>
                        </div>
                        <div
                          className="d-flex justify-content-between fs-5 fw-bold "
                          //   style={{ backgroundColor: 'gray' }}
                        >
                          <span className="p-2 m-0 text-primary">
                            Total due:{' '}
                          </span>{' '}
                          <span>₦{total.toLocaleString()}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="text-start">
                      Please pay within 15 days of receiving this invoice
                    </p>
                  </div>
                </div>
              </section>
              <hr />
              <footer className="text-start p-5 pt-0 d-flex justify-content-between">
                <div className="text-start fw-bold">
                  <p>Thank you for the Business!</p>
                </div>
                <div className="text-start d-flex gap-2">
                  <p>+2345637383833</p> | <p>helloe@email.com</p>
                </div>
              </footer>
            </div>
            <section className="buttons">
              <div className="d-flex justify-content-end gap-2 mt-2">
                <Button
                  classes="primary-btn"
                  content="Edit"
                  onClick={() => setShowInvoice(false)}
                />
                <Button classes="primary-btn" content="Save" type="submit" />
                <Button classes="primary-btn" content="View" />
                <ReactToPrint
                  trigger={() => (
                    <Button classes="primary-btn" content="Print/Download" />
                  )}
                  content={() => componentRef.current}
                />
              </div>
            </section>
          </>
        ) : (
          <>
            <div style={{ position: 'relative' }}>
              <button
                onClick={handleClose}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  background: 'transparent',
                  border: 'none',
                  fontSize: '24px',
                  cursor: 'pointer',
                }}>
                &times;
              </button>
            </div>
            <div className="guarantor-list bg-white">
              <h3 className="mb-4" style={{ color: '#054b99' }}>
                Edit Invoice Details (Template 3)
              </h3>
              <div className="account-settings-form">
                <div className="row px-md-5 px-4 justify-content-between text-start">
                  <div className="col-12 col-md-4 left-side pe-md-3">
                    <div className="col-md-12 input-box">
                      <label htmlFor="avatar" className="mb-1 mt-3">
                        Logo
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="avatar"
                        name="companyLogo"
                        onChange={handleInputChange}
                        value={businessDetails.companyLogo}
                        accept="image/png, image/jpeg"
                      />
                    </div>
                    <div className="d-flex col-md-12 gap-4">
                      <div className="col-md-5 input-box">
                        <label className="mb-1 mt-3" htmlFor="name">
                          City
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="city"
                          name="companyCity"
                          value={businessDetails.companyCity}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="col-md-5 input-box">
                        <label className="mb-1 mt-3" htmlFor="name">
                          State
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="state"
                          name="companyState"
                          value={businessDetails.companyState}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12 input-box">
                      <h6 className="mt-4">Bill to: </h6>
                      <label className="mb-1 mt-3" htmlFor="name">
                        Customer
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        name="customer"
                        value={invoiceDetails.companyName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-md-12 input-box">
                      <label className="mb-1 mt-3" htmlFor="address">
                        Address
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="address"
                        name="companyAddress"
                        value={invoiceDetails.companyAddress}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="d-flex col-md-12 gap-4">
                      <div className="col-md-5 input-box">
                        <label className="mb-1 mt-3" htmlFor="state">
                          State
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="customer_state"
                          name="customerState"
                          value={invoiceDetails.companyAddress}
                          // value={invoiceDetails.customerState}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="col-md-5 input-box">
                        <label className="mb-1 mt-3" htmlFor="city">
                          City
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="customer_city"
                          name="customerCity"
                          value={invoiceDetails.companyAddress}
                          // value={invoiceDetails.customerCity}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12 input-box">
                      <label className="mb-1 mt-3" htmlFor="phone">
                        Phone number
                      </label>
                      <input
                        className="form-control"
                        type="tel"
                        id="phone"
                        name="customerPhone"
                        placeholder="+234-12345678"
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        value={invoiceDetails.phoneNumber}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-md-12 input-box">
                      <label className="mb-1 mt-3" htmlFor="phone">
                        Subject
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="subject"
                        name="subject"
                        // value={invoiceDetails.subject}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-md-12 input-box">
                      <label className="mb-1 mt-3" htmlFor="tax_number">
                        Tax Number
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        id="tax_number"
                        name="taxNumber"
                        // value={invoiceDetails.taxNumber}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 middle-side px-md-3">
                    {/* <h4>Invoice Information</h4> */}
                    <div className="col-md-12 input-box">
                      <label className="mb-1 mt-3" htmlFor="number">
                        Invoice number:
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="invoice_number"
                        name="invoiceNumber"
                        value={invoiceDetails.invoiceNumber}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-md-12 input-box">
                      <label className="mb-1 mt-3" htmlFor="date">
                        Invoice date
                      </label>
                      <input
                        className="form-control"
                        type="date"
                        id="invoice_date"
                        name="invoiceDate"
                        value={invoiceDetails.invoiceDate}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-md-12 input-box">
                      <label className="mb-1 mt-3" htmlFor="date">
                        Payment due
                      </label>
                      <input
                        className="form-control"
                        type="date"
                        id="payment_date"
                        name="paymentDue"
                        value={invoiceDetails.paymentDueDate}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-md-12 input-box">
                      <label className="mb-1 mt-3" htmlFor="name">
                        Name/Company
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        name="companyName"
                        value={invoiceDetails.companyName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-md-12 input-box">
                      <label className="mb-1 mt-3" htmlFor="address">
                        Address
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="company_address"
                        name="companyAddress"
                        value={invoiceDetails.companyAddress}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 right-side ps-md-3">
                    <div className="col-md-12 input-box">
                      <label className="mb-1 mt-3" htmlFor="email">
                        Email
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        id="company_email"
                        name="companyEmail"
                        value={invoiceDetails.email}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-md-12 input-box">
                      <label className="mb-1 mt-3" htmlFor="phone">
                        Phone number
                      </label>
                      <input
                        className="form-control"
                        type="tel"
                        id="company_phone"
                        name="companyPhone"
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        value={invoiceDetails.phoneNumber}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-md-12 input-box">
                      <label className="mb-1 mt-3" htmlFor="description">
                        Item Description
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="description"
                        name="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                      />
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="col-md-12 d-flex gap-3">
                        <div className="col-md-3 input-box">
                          <label className="mb-1 mt-3" htmlFor="quantity">
                            Quantity
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="quantity"
                            name="quantity"
                            value={quantity}
                            onChange={(e) =>
                              setQuantity(Number(e.target.value))
                            }
                            required
                          />
                        </div>
                        <div className="col-md-4 input-box">
                          <label className="mb-1 mt-3" htmlFor="price">
                            Price
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="price"
                            name="price"
                            value={price}
                            onChange={(e) => setPrice(Number(e.target.value))}
                            required
                          />
                        </div>
                        <div className="col-md-3 input-box">
                          <label className="mb-1 mt-3" htmlFor="amount">
                            Amount
                          </label>
                          <p>{amount}</p>
                        </div>
                      </div>
                      <button type="submit" className="primary-btn p-1 mt-1">
                        Add Item
                      </button>
                    </form>
                    <div className="col-md-12 input-box">
                      <label className="mb-1 mt-3" htmlFor="notes">
                        Terms & Conditions
                      </label>
                      <textarea
                        className="form-control"
                        id="notes"
                        name="notes"
                        placeholder="e.g Please Pay within 15days of receiving this invoice"
                        value={invoiceDetails.termsAndConditions}
                        onChange={handleInputChange}
                        cols={35}
                        rows={3}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row px-md-5 px-4 justify-content-end">
                <div className="col-12">
                  <table className="table responsive-table mb-0 col-md-12">
                    <thead>
                      <tr>
                        <td className="fw-bold">ITEM</td>
                        <td className="fw-bold">QTY</td>
                        <td className="fw-bold">RATE</td>
                        <td className="fw-bold">AMOUNT</td>
                      </tr>
                    </thead>
                    {list.map(
                      ({ id, description, quantity, price, amount }) => (
                        <tbody key={id}>
                          <tr>
                            <td>{description}</td>
                            <td>{quantity}</td>
                            <td>{price}</td>
                            <td>₦{amount.toFixed(2)}</td>
                            <td>
                              <button
                                onClick={() => handleDeleteItem(id)}
                                className="bg-transparent">
                                <Icon
                                  path={mdiDeleteEmpty}
                                  size={1}
                                  className=""
                                  style={{
                                    color: '#e75858',
                                  }}
                                />
                              </button>
                            </td>
                            <td>
                              <button
                                onClick={() => editRow(id)}
                                className="bg-transparent">
                                <Icon
                                  path={mdiPencilOutline}
                                  size={1}
                                  className=""
                                  style={{
                                    color: '#06A77D',
                                  }}
                                />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ),
                    )}
                  </table>
                </div>
              </div>
              <div>
                <Button
                  classes="btn primary-btn me-2"
                  onClick={() => setShowInvoice(true)}
                  content="Preview Invoice"
                />
              </div>
            </div>
          </>
        )}
      </main>
    </>
  );
};

export default observer(InvoiceFormThree);
