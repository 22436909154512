/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  mdiAccountGroup,
  mdiBookOpenBlankVariant,
  mdiChartBox,
  mdiCog,
  mdiContentCopy,
  mdiFlowerTulip,
  mdiLogout,
  mdiShieldCheck,
  mdiViewDashboard,
  mdiWallet,
} from '@mdi/js';
import Icon from '@mdi/react';
import { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Spinner from '../../../components/ui/Spinner';
import { StoreContext } from '../../../mobx_stores/RootStore';
import { clearReduxStateAction } from '../../../redux/root-reducer';
import { LogOut } from '../../../utils/firebase/AuthFirestore';

const Sidenav = () => {
  const { loansStore, profileStore } = useContext(StoreContext);
  const { profile } = profileStore;
  const { loanUserdetails } = loansStore;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleLogOut = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    await LogOut();
    dispatch(clearReduxStateAction());
    window.location.href = '/login';
    setTimeout(() => {
      setLoading(false);
      window.location.reload();
    }, 500);
  };

  const [referralCode, setReferralCode] = useState('');

  const referralCodeRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setReferralCode(profile?.personalReferalCode);
  }, [profile?.personalReferalCode]);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(referralCode)
      .then(() => {
        setReferralCode('Copied!');
        setTimeout(() => {
          setReferralCode(profile?.personalReferalCode);
        }, 2000);
      })
      .catch(() => {
        return;
      });
  };

  return (
    <>
      {' '}
      {loading && <Spinner />}
      <nav className="sidenav">
        <div className="sidenav-container">
          <div className="logo">
            <img
              src={`${BaseDirectories.LOGOS_DIR}/TradeLendaLOGO.png`}
              alt=""
            />
          </div>
          <hr />
          <div>
            <ul>
              {referralCode && (
                <li
                  className="referral-code-box"
                  style={{ color: '#4e4b66', fontSize: '13px' }}>
                  <span>Personal referral code:</span>
                  <div>
                    <p ref={referralCodeRef}>{referralCode}</p>{' '}
                    <span
                      onClick={copyToClipboard}
                      className="copy-button"
                      role="button"
                      title="Copy">
                      <Icon path={mdiContentCopy} size={0.7} />
                    </span>
                  </div>
                </li>
              )}
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive || window.location.pathname.includes('overview/')
                      ? 'sidenav-link active'
                      : 'sidenav-link'
                  }
                  to="overview">
                  <Icon path={mdiViewDashboard} vertical size={1} />
                  <span>Overview</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive || window.location.pathname.includes('loans/')
                      ? 'sidenav-link active'
                      : 'sidenav-link'
                  }
                  to={
                    loanUserdetails?.organizationDetails?.shariaCom
                      ? 'facility'
                      : 'loans'
                  }>
                  <Icon path={mdiChartBox} size={1} />
                  <span>
                    {' '}
                    {loanUserdetails?.organizationDetails?.shariaCom
                      ? 'Facility'
                      : 'Loans'}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive || window.location.pathname.includes('guarantors/')
                      ? 'sidenav-link active'
                      : 'sidenav-link'
                  }
                  to="guarantors/all_guarantors">
                  <Icon path={mdiAccountGroup} size={1} />
                  <span>Guarantors</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive || window.location.pathname.includes('invest/')
                      ? 'sidenav-link active'
                      : 'sidenav-link'
                  }
                  to="invest">
                  <Icon path={mdiFlowerTulip} size={1} />
                  <span>Invest</span>
                </NavLink>
              </li>
              {/* New SideNav Links starts */}
              {/* <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive || window.location.pathname.includes('invoices/')
                      ? 'sidenav-link active'
                      : 'sidenav-link'
                  }
                  to="invoices/all_invoices">
                  <Icon path={mdiShieldCheck} size={1} />
                  <span>Invoices</span>
                </NavLink>
              </li> */}

              {/* <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ||
                    window.location.pathname.includes('bookkeeping/')
                      ? 'sidenav-link active'
                      : 'sidenav-link'
                  }
                  to="bookkeeping/all_bookkeeping">
                  <Icon path={mdiBookOpenBlankVariant} size={1} />
                  <span>Bookkeeping</span>
                </NavLink>
              </li> */}
              {/* New SideNav Links ends */}
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive || window.location.pathname.includes('wallet/')
                      ? 'sidenav-link active'
                      : 'sidenav-link'
                  }
                  to="wallet">
                  <Icon path={mdiWallet} size={1} />
                  <span>Wallet</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive || window.location.pathname.includes('settings/')
                      ? 'sidenav-link active'
                      : 'sidenav-link'
                  }
                  to="settings/account_settings">
                  <Icon path={mdiCog} size={1} />
                  <span>Settings</span>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="sidebar-logout-btn">
            <button type="button" onClick={handleLogOut}>
              <Icon path={mdiLogout} title="User Profile" size={1} horizontal />
              <span>Sign out</span>
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Sidenav;
